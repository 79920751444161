<script setup>
import { ref, defineProps, defineEmits, watch} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const props = defineProps({
  sports: {
    type: Object,
    required: true,
  },
  currentLiveSportId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(["hideSidemenu"]);

const router = useRouter();
const store = useStore();

console.log(props.sports);

const favorites = ref([]);


const toggleFavorite = (sportName, event) => {
  event.preventDefault();
  if (favorites.value.includes(sportName)) {
    favorites.value = favorites.value.filter((f) => f !== sportName);
  } else {
    favorites.value.push(sportName);
  }
};

const navigateToSport = async (sportId) => {
  if (sportId) {
    emit("hideSidemenu"); // Emit event to hide sidemenu
    router.push(`/precountries/${sportId}`);
    await store.dispatch("updateCurrentSportId", sportId);
  }
};

const navigateToHome = () => {
  emit("hideSidemenu"); // Emit event to hide sidemenu
  router.push('/');
};
console.log(props.currentLiveSportId);
const navigateToCategory = (category) => {
  emit("hideSidemenu"); // Emit event to hide sidemenu
  if (category === 'Sportsbets') {
    router.push('/');
  } else if (category === 'In Play') {
    router.push(`/inplay/${props.currentLiveSportId}`);
  }
  // For 'Casino', you can add a specific route if needed
};

// Watch for changes in the loading state
watch(
  () => store.getters.isLoading,
  (newLoadingState) => {
    if (newLoadingState) {
      emit("hideSidemenu");
    }
  }
);
</script>

<template>
  <div>
    <!-- Search Bar -->
    <div class="p-0 w-full border-b border-custom-bg-gray">
      <div class="flex items-center bg-custom-bg-2 rounded-md w-full">
        <i class="ri-search-line text-custom-bg-4 ml-3"></i>
        <input
          type="text"
          placeholder="Search..."
          class="bg-transparent text-custom-bg-4 placeholder:text-custom-bg-4 p-2 w-full focus:outline-none"
        />
      </div>
    </div>

    <!-- Main Categories -->
    <div class="flex justify-around p-0 w-full">
      <div
        v-for="category in ['Sportsbets', 'In Play', 'Casino']"
        :key="category"
        class="flex flex-col items-center cursor-pointer"
        @click="navigateToCategory(category)"
      >
        <img
          :src="`/icons/${category.toLowerCase().replace(' ', '')}.PNG`"
          class="h-9 w-10"
          :alt="category"
        />
        <span class="text-xs">{{ category }}</span>
      </div>
    </div>

    <div
      class="mt-4 overflow-y-auto w-full h-screen"
      style="max-height: calc(100vh - 200px)"
    >
      <div
        class="flex items-center justify-between px-2 py-2 hover:cursor-pointer border-t border-b border-custom-bg-gray w-full"
        @click="navigateToHome"
      >
        <span>Home</span>
        <svg
          class="svg-inline--fa fa-house h-5 w-5 text-custom-bg-4"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="house"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32 32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
          />
        </svg>
      </div>
      <div
        v-for="sport in sports.data"
        :key="sport.id"
        @click="navigateToSport(sport.id)"
        class="flex items-center justify-between px-2 py-2 hover:cursor-pointer border-b border-custom-bg-gray w-full"
      >
        <div class="flex items-center">
          <img
            :src="`/icons/${sport.name.toLowerCase().replace(' ', '')}.PNG`"
            :alt="sport.name + ' icon'"
            class="mr-2 h-7 w-8"
          />
          <span>{{ sport.name }}</span>
        </div>
        <svg
          class="svg-inline--fa fa-star h-5 w-5 cursor-pointer"
          :class="{
            'text-yellow-400': favorites.includes(sport.name),
            'text-custom-bg-4': !favorites.includes(sport.name),
          }"
          @click.stop="(event) => toggleFavorite(sport.name, event)"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="star"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0L287.9 0zM287.9 78.95L235.4 187.2C231.9 194.3 225.1 199.3 217.3 200.5L98.98 217.9L184.9 303C190.4 308.5 192.9 316.4 191.6 324.1L171.4 443.7L276.6 387.5C283.7 383.7 292.2 383.7 299.2 387.5L404.4 443.7L384.2 324.1C382.9 316.4 385.5 308.5 391 303L476.9 217.9L358.6 200.5C350.7 199.3 343.9 194.3 340.5 187.2L287.9 78.95z"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>