<template>
  <div class="bg-custom-bg-4 p-1 rounded-lg border border-custom-bg-gray-2 w-full max-w-2xl mx-auto">
    <!-- Match Events List -->
    <div class="space-y-4">
      <div v-for="event in sortedEvents" :key="event.id" 
           class="grid grid-cols-[1fr,auto,1fr] gap-0 items-center">
        <!-- Home Team Event -->
        <div :class="['pr-2', { 'border-r border-red-500': event.team === 'home' }]">
          <div v-if="event.team === 'home'" class="flex items-center justify-end space-x-2">
            <span class="text-white">
              {{ formatEventText(event.text) }}
              <span v-if="event.type === 'yellowcard'" class="inline-flex items-center">
                [<div class="w-3 h-4 bg-yellow-400 mx-1"></div>Yellow Card]
              </span>
              <span v-if="event.type === 'redcard'" class="inline-flex items-center">
                [<div class="w-3 h-4 bg-red-600 mx-1"></div>Red Card]
              </span>
            </span>
            <span v-if="event.type === 'goal'" class="text-white">⚽</span>
            <span v-if="event.type === 'corner'" class="text-red-500">
              <FlagIcon class="w-4 h-4" />
            </span>
          </div>
        </div>

        <!-- Timestamp -->
        <div class="text-white font-medium bg-transparent px-2 text-center">
          {{ event.time }}'
        </div>

        <!-- Away Team Event -->
        <div :class="['pl-2', { 'border-l border-red-500': event.team === 'away' }]">
          <div v-if="event.team === 'away'" class="flex items-center space-x-2">
            <span v-if="event.type === 'goal'" class="text-white">⚽</span>
            <span v-if="event.type === 'corner'" class="text-red-500">
              <FlagIcon class="w-4 h-4" />
            </span>
            <span class="text-white">
              {{ formatEventText(event.text) }}
              <span v-if="event.type === 'yellowcard'" class="inline-flex items-center">
                [<div class="w-3 h-4 bg-yellow-400 mx-1"></div>Yellow Card]
              </span>
              <span v-if="event.type === 'redcard'" class="inline-flex items-center">
                [<div class="w-3 h-4 bg-red-600 mx-1"></div>Red Card]
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- Score Updates -->
      <div v-for="score in scoreUpdates" :key="score.id" 
           class="text-yellow-400 text-center font-medium py-2">
        {{ score.text }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { FlagIcon } from 'lucide-vue-next'

const props = defineProps({
  events: {
    type: Array,
    required: true
  },
  homeTeam: {
    type: String,
    required: true
  },
  awayTeam: {
    type: String,
    required: true
  }
})

const determineEventType = (text) => {
  if (text.includes('Yellow Card')) return 'yellowcard'
  if (text.includes('Red Card')) return 'redcard'
  if (text.includes('Goal')) return 'goal'
  if (text.includes('Corner')) return 'corner'
  return 'other'
}

const determineEventTeam = (text) => {
  if (text.includes(props.homeTeam)) return 'home'
  if (text.includes(props.awayTeam)) return 'away'
  return 'home'
}

const formatEventText = (text) => {
  // Remove the minute prefix
  let formatted = text.replace(/^\d+'\s*-\s*/, '')
  // Remove ordinal indicators
  formatted = formatted.replace(/\d+(st|nd|rd|th)\s+/, '')
  // Remove team names
  formatted = formatted.replace(new RegExp(`(${props.homeTeam}|${props.awayTeam})`, 'g'), '')
  // Clean up extra spaces and dashes
  formatted = formatted.replace(/\s+/g, ' ').replace(/\s*-\s*/g, '').trim()
  return formatted
}

const sortedEvents = computed(() => {
  return props.events
    .map(event => ({
      ...event,
      time: event.text.split("'")[0],
      type: determineEventType(event.text),
      team: determineEventTeam(event.text)
    }))
    .sort((a, b) => parseInt(b.time) - parseInt(a.time))
})

const scoreUpdates = computed(() => {
  return props.events.filter(event => 
    event.text.includes('Score After') || 
    event.text.includes('Score At Start')
  )
})
</script>

<style scoped>
.grid {
  margin: 0.5rem 0;
}
</style>