<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

defineProps({
  sports: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["updateLoading"]);

const router = useRouter();
const store = useStore();

const navigateToSport = async (sportId) => {
  if (sportId) {
    emit("updateLoading", true);
    try {
      await store.dispatch("updateCurrentSportId", sportId);
      router.push(`/precountries/${sportId}`);
    } catch (error) {
      console.error('Error updating sport:', error);
    } finally {
      emit("updateLoading", false);
    }
  } else {
    console.error('Invalid sport ID');
  }
};
</script>

<template>
  <div class="w-full bg-custom-bg-gray overflow-x-auto scrollbar-hide">
    <div class="flex space-x-4 py-1 px-1 w-full min-w-full">
      <div
        v-for="sport in sports.data"
        :key="sport.id"
        class="flex flex-col items-center flex-shrink-0 cursor-pointer"
        @click="navigateToSport(sport.id)"
      >
        <img
          v-if="sport.name"
          class="text-2xl mb-1 h-7 w-8"
          :src="`/icons/${sport.name.toLowerCase().replace(' ', '')}.svg`"
          :alt="sport.name"
        />
        <img
          v-else
          class="text-2xl mb-1 h-7 w-8"
          src="/icons/default-sport.svg"
          alt="Unknown Sport"
        />
        <div class="text-white text-sm whitespace-nowrap">{{ sport.name || 'Unknown Sport' }}</div>
      </div>
    </div>
  </div>
</template>