<template>
  <div
    class="fixed top-[40px] right-0 w-[90vw] md:w-96 bg-gray-100 overflow-hidden transition-all duration-300 ease-in-out z-40"
    :style="{
      transform: isPanelOpen ? 'translateX(0)' : 'translateX(100%)',
      height: 'calc(100vh - 40px)',
      maxHeight: '75vh'
    }"
  >
    <!-- Fixed Header -->
    <div class="absolute top-0 left-0 right-0 bg-gray-100 z-10 border-b border-gray-300">
      <div class="flex justify-between items-center p-4">
        <h2 class="text-lg font-semibold text-gray-800">Account</h2>
        <button @click="$emit('close')" class="text-gray-600 hover:text-gray-800">
          <i class="ri-close-line text-xl"></i>
        </button>
      </div>
    </div>

    <!-- Scrollable Content -->
    <div class="h-full overflow-y-auto pt-[60px]">
      <!-- Username Section -->
      <div class="border-b border-gray-300 p-4">
        <div class="text-sm text-gray-600">Username</div>
        <div class="text-lg">{{ user.username || 'N/A' }}</div>
      </div>

      <!-- Dynamic Wallet Sections -->
      <div v-for="wallet in user.wallets" :key="wallet.id" class="border-b border-gray-300 p-4">
        <div class="text-sm text-gray-600">{{ wallet.currency.name }} Balance</div>
        <div class="flex justify-between items-center">
          <span class="text-lg">{{ wallet.balance }}</span>
          <span class="text-sm text-gray-600">{{ wallet.currency.code }}</span>
        </div>
      </div>

      <!-- Navigation Links -->
      <div>
        <router-link
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.path"
          class="block w-full p-4 text-gray-700 border-b border-gray-300 hover:bg-gray-200 transition-colors duration-200"
        >
          {{ item.label }}
        </router-link>
      </div>

      <!-- Logout Button -->
      <button 
        @click="handleLogout" 
        class="w-full text-left p-4 text-gray-700 border-b border-gray-300 hover:bg-gray-200 transition-colors duration-200"
      >
        Logout
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

defineProps({
  isPanelOpen: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['logout', 'close'])

const user = computed(() => store.getters.user)

const menuItems = [
  { label: 'Messages', path: '/messages' },
  { label: 'Users list', path: '/users' },
  { label: 'Account Balance', path: '/balance' },
  { label: 'Create New User', path: '/create-user' },
  { label: 'Change password', path: '/change-password' },
  { label: 'Info dhe Rregullore', path: '/info' },
]

const handleLogout = () => {
  emit('logout')
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-y-auto::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-y-auto {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>