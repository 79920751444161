<template>
  <div class="min-h-screen bg-gray-100">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <div class="bg-custom-black py-4 px-4">
          <div class="w-full max-w-4xl mx-auto">
            <!-- Header with All dropdown -->
            <div class="flex justify-between items-center mb-4">
              <div class="flex items-center">
                <button class="text-gray-400 mr-2">
                  <ChevronLeftIcon class="h-6 w-6" />
                </button>
                <h2 class="text-xl font-semibold text-white">Account Balance</h2>
              </div>
              <div class="relative">
                <button 
                  @click="toggleDropdown"
                  class="flex items-center text-white space-x-1"
                >
                  <span>All</span>
                  <ChevronDownIcon class="h-5 w-5" />
                </button>
                <!-- Dropdown menu (add your options here) -->
              </div>
            </div>

            <!-- Date Range Picker -->
            <div class="flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
              <input
                type="date"
                v-model="dateRange.start"
                class="w-full sm:w-auto bg-white rounded px-4 py-2 text-custom-black-2"
              />
              <input
                type="date"
                v-model="dateRange.end"
                class="w-full sm:w-auto bg-white rounded px-4 py-2 text-custom-black-2"
              />
              <button 
                @click="applyDateFilter"
                class="w-full sm:w-auto bg-custom-green text-white px-6 py-2 rounded hover:bg-custom-bg-green transition-colors"
              >
                Filtro
              </button>
            </div>
            
            <!-- Quick Filters -->
            <div class="flex flex-wrap space-x-4 sm:space-x-6 mb-6 border-b border-custom-bg-2">
              <button 
                v-for="filter in quickFilters" 
                :key="filter.value"
                @click="currentFilter = filter.value"
                :class="[
                  'pb-2 px-1 text-gray-300 relative',
                  currentFilter === filter.value 
                    ? 'text-white border-b-2 border-red-500 -mb-[1px]' 
                    : 'hover:text-white'
                ]"
              >
                {{ filter.label }}
              </button>
            </div>

            <!-- Responsive Table/Card Layout -->
            <div class="custom-black-2 rounded-lg overflow-hidden">
              <!-- Table for larger screens -->
              <table class="w-full text-left hidden sm:table">
                <thead>
                  <tr class="bg-gray-700 text-gray-300">
                    <th class="p-4">Date</th>
                    <th class="p-4">Description</th>
                    <th class="p-4">Amount</th>
                    <th class="p-4">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, index) in filteredTransactions" :key="index" class="border-b border-custom-bg-2 text-gray-300">
                    <td class="p-4">{{ formatDate(transaction.date) }}</td>
                    <td class="p-4">{{ transaction.description }}</td>
                    <td class="p-4" :class="transaction.amount > 0 ? 'text-green-500' : 'text-red-500'">
                      {{ transaction.amount > 0 ? '+' : '' }}{{ transaction.amount.toFixed(2) }} LEK
                    </td>
                    <td class="p-4">{{ transaction.balance.toFixed(2) }} LEK</td>
                  </tr>
                </tbody>
              </table>

              <!-- Card layout for smaller screens -->
              <div class="sm:hidden">
                <div v-for="(transaction, index) in filteredTransactions" :key="index" class="border-b border-custom-bg-2 p-4 text-gray-300">
                  <div class="flex justify-between items-center mb-2">
                    <span class="font-semibold">{{ formatDate(transaction.date) }}</span>
                    <span :class="transaction.amount > 0 ? 'text-green-500' : 'text-red-500'">
                      {{ transaction.amount > 0 ? '+' : '' }}{{ transaction.amount.toFixed(2) }} LEK
                    </span>
                  </div>
                  <div class="mb-2">{{ transaction.description }}</div>
                  <div class="text-sm text-gray-400">Balance: {{ transaction.balance.toFixed(2) }} LEK</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ChevronLeftIcon, ChevronDownIcon } from 'lucide-vue-next'
import MainLayout from "../layouts/MainLayout.vue"
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue"

const store = useStore()
const isLoading = computed(() => store.state.isLoading)
const sports = computed(() => store.state.sports || [])

const currentFilter = ref('today')
const showDropdown = ref(false)

const dateRange = ref({
  start: new Date().toISOString().split('T')[0],
  end: new Date().toISOString().split('T')[0]
})

const quickFilters = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'November', value: 'november' },
  { label: 'October', value: 'october' },
]

// Dummy data with today's transactions
const transactions = ref([
  {
    date: new Date().toISOString(),
    description: 'Initial Deposit',
    amount: 5000,
    balance: 5000
  },
  {
    date: new Date().toISOString(),
    description: 'Bet Placed',
    amount: -1000,
    balance: 4000
  },
  {
    date: new Date().toISOString(),
    description: 'Bet Won',
    amount: 2000,
    balance: 6000
  },
  {
    date: new Date(Date.now() - 86400000).toISOString(), // Yesterday
    description: 'Withdrawal',
    amount: -500,
    balance: 5500
  },
  {
    date: new Date(Date.now() - 86400000 * 2).toISOString(),
    description: 'Deposit',
    amount: 1000,
    balance: 6500
  }
])

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value
}

const applyDateFilter = () => {
  // Implementation for custom date range filter
  console.log('Applying date filter:', dateRange.value)
}

const formatDate = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-GB')
}

const filteredTransactions = computed(() => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  
  return transactions.value.filter(transaction => {
    const transactionDate = new Date(transaction.date)
    transactionDate.setHours(0, 0, 0, 0)

    switch (currentFilter.value) {
      case 'today':
        return transactionDate.getTime() === today.getTime()
      case 'yesterday':
        return transactionDate.getTime() === yesterday.getTime()
      case 'november':
        return transactionDate.getMonth() === 10 // JavaScript months are 0-based
      case 'october':
        return transactionDate.getMonth() === 9
      default:
        return true
    }
  })
})

onMounted(async () => {
  try {
    await store.dispatch("fetchSports")
  } catch (error) {
    console.error("Failed to fetch sports data:", error)
  }
})
</script>

<style scoped>
/* Hide default date input styling */
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .flex-wrap > * {
    margin-bottom: 0.5rem;
  }
}
</style>