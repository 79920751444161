<script setup>
import { ref, defineProps, computed, watch } from "vue";
import { useStore} from "vuex";
import { useRouter } from "vue-router";
import SideMenu from "../components/SideMenu.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";
import BetSlip from "../components/BetSlip.vue";
import LoginModal from "../components/auth/LoginComponent.vue";
import UserAccountPanel from "../components/UserAccountPanel.vue";

const store = useStore();
const router = useRouter();
const isLoading = computed(() => store.state.isLoading);
const isBetSlipVisible = computed(() => store.state.betslip.isVisible);
const currentLiveSportId = computed(() => store.state.currentLiveSportId);
const isAuthenticated = computed(() => store.getters.isAuthenticated);
const user = computed(() => store.getters.user);


const isSideMenuOpen = ref(false);
const isLoginModalOpen = ref(false);
const isAccountPanelOpen = ref(false);

defineProps({
  sports: {
    type: Array,
    required: true,
  },
});

const toggleSideMenu = () => {
  if (!isLoading.value) {
    isSideMenuOpen.value = !isSideMenuOpen.value;
  }
};

const navigateToInPlay = () => {
      router.push(`/inplay/${currentLiveSportId.value}`);
    };

watch(isLoading, (newLoadingState) => {
  if (newLoadingState) {
    isSideMenuOpen.value = false;
  }
});

const hideSideMenu = () => {
  isSideMenuOpen.value = false;
};

const openLoginModal = () => {
  isLoginModalOpen.value = true;
};

const closeLoginModal = () => {
  isLoginModalOpen.value = false;
};

const handleLoginSuccess = () => {
  closeLoginModal();
};

const logout = () => {
  store.dispatch("logout");
  isAccountPanelOpen.value = false;
};

const handleShowLoginModal = () => {
  openLoginModal();
};

const toggleAccountPanel = () => {
  isAccountPanelOpen.value = !isAccountPanelOpen.value;
};
</script>

<template>
  <div
    class="flex flex-col min-h-screen bg-body-bg text-sm"
    :class="{ 'overflow-hidden h-screen': isLoginModalOpen }"
  >
    <!-- Side Menu -->
    <div
      class="fixed inset-y-0 left-0 w-72 bg-custom-bg-2 text-custom-white transform transition-transform duration-300 ease-in-out overflow-hidden z-50"
      :class="
        isSideMenuOpen && !isLoading ? 'translate-x-0' : '-translate-x-full'
      "
    >
      <SideMenu
        :sports="sports"
        @hideSidemenu="hideSideMenu"
        :currentLiveSportId="currentLiveSportId"
      />
    </div>

    <div
      class="transition-all duration-300 ease-in-out flex flex-col min-h-screen"
      :class="[isSideMenuOpen && !isLoading ? 'ml-72' : 'ml-0', 'w-full']"
    >
      <!-- Header -->
      <nav class="bg-custom-bg-green text-white text-sm p-1 sticky top-0 z-40">
        <div class="container mx-auto flex justify-between items-center">
          <div
            class="flex items-center space-x-4 cursor-pointer"
            @click="toggleSideMenu"
          >
            <i
              :class="
                isSideMenuOpen && !isLoading ? 'ri-close-line' : 'ri-menu-line'
              "
              class="text-sm text-custom-green"
            ></i>
            <div class="flex items-center">
              <span class="text-xs">A-Z</span>
            </div>
          </div>
          <div class="flex space-x-6 cursor-pointer" @click="navigateToInPlay">
            <a href="#" class="hover:text-yellow-300">In Play</a>
            <a
              href="#"
              class="flex items-center border-b-2 border-yellow-300 pb-1"
            >
              <img src="/icons/logonew.png" alt="itbet365 logo" class="h-6" />
            </a>
          </div>
          <div v-if="isAuthenticated" class="flex items-center space-x-4">
            <router-link
              to="/my-bets"
              class="text-custom-yellow hover:text-custom-yellow-bright"
            >
              My Bets
            </router-link>
            <button
              @click="toggleAccountPanel"
              class="text-custom-yellow hover:text-custom-yellow-bright"
            >
              <i class="ri-account-circle-line text-2xl font-light"></i>
            </button>
          </div>
          <button
            v-else
            @click="openLoginModal"
            class="flex items-center space-x-2 bg-custom-bg-green px-4 py-1 rounded hover:bg-custom-bg-green-dark"
          >
            <span class="text-custom-yellow">Login</span>
          </button>
        </div>
      </nav>

      <!-- Main Content -->
      <main class="flex-grow w-full relative">
        <!-- Global loading spinner -->
        <div
          v-if="isLoading"
          class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <SimpleSpinner />
        </div>
        <slot v-else></slot>
      </main>

      <!-- Footer -->
      <footer class="bg-custom-bg-gray text-white text-sm">
        <div class="container mx-auto px-4">
          <div class="py-2 text-center border-b border-custom-bg-3">
            <button class="hover:underline">Back To Top</button>
          </div>
          <div class="py-6 flex justify-between items-center">
            <div class="text-2xl font-bold">itbet365 Sport</div>
            <select class="bg-white text-black px-4 py-2 rounded">
              <option>English</option>
            </select>
          </div>
          <div class="py-4 flex justify-center space-x-6">
            <a href="#" class="hover:underline">Terms and Conditions</a>
            <a href="#" class="hover:underline">Responsible Gambling</a>
            <a href="#" class="hover:underline">Privacy Policy</a>
          </div>
          <div class="py-4 text-center text-xs text-custom-white">
            <p>
              By accessing, continuing to use or navigating throughout this site
              you accept that we will use certain browser cookies to improve
              your customer experience with us. Our site only uses cookies which
              will improve your experience with us and will not interfere with
              your privacy. Please refer to our Cookies Policy for further
              information on our use of cookies and how you can disable or
              manage their use should you wish.
            </p>
          </div>
          <div class="py-4 text-center text-xs">
            <p>
              Gambling can be addictive, please play responsibly. For
              information on support measures, please visit our Responsible
              Gambling Help page.
            </p>
          </div>
          <div class="py-4 text-center text-xs text-custom-white">
            <p>© 2016-2023 All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>

    <!-- User Account Panel -->
    <UserAccountPanel
      v-if="isAuthenticated"
      :user="user"
      :isPanelOpen="isAccountPanelOpen"
      @logout="logout"
      @close="toggleAccountPanel"
    />

    <!-- BetSlip -->
    <BetSlip v-if="isBetSlipVisible" @showLoginModal="handleShowLoginModal" />

    <!-- Login Modal -->
    <LoginModal
      v-if="isLoginModalOpen"
      @close="closeLoginModal"
      @login-success="handleLoginSuccess"
    />
  </div>
</template>

<style scoped>
/* Add any additional custom styles here */
</style>