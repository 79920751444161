<template>
  <MainLayout :sports="sports">
    <div class="bg-custom-black py-8 px-4">
      <div class="w-full max-w-7xl mx-auto">
        <h1 class="text-2xl font-bold text-white mb-6">{{ headerText }}</h1>

        <!-- My Balance Section -->
        <div class="bg-custom-bg-2 rounded-lg overflow-hidden mb-6">
          <h3 class="text-lg font-semibold text-white p-4 bg-custom-bg-2">
            My Balance
          </h3>
          <div class="overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="text-gray-300 text-sm">
                  <th class="p-4 text-left"></th>
                  <th
                    v-for="currency in currencies"
                    :key="currency"
                    class="p-4 text-right"
                  >
                    {{ currency }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-white">
                <tr class="border-t border-custom-black">
                  <td class="p-4">Balance</td>
                  <td
                    v-for="currency in currencies"
                    :key="currency"
                    class="p-4 text-right"
                    :class="{
                      'text-red-500':
                        getUserBalance(userData, currency) < 0,
                    }"
                  >
                    {{ formatNumber(getUserBalance(userData, currency)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Balance for each user Section -->
        <div class="bg-custom-bg-2 rounded-lg overflow-hidden">
          <div class="flex justify-between items-center p-4 bg-custom-bg-2">
            <h3 class="text-lg font-semibold text-white">
              Balance for each user
            </h3>
            <div class="flex space-x-2">
              <div class="flex items-center">
                <span class="w-3 h-3 bg-red-500 rounded-full mr-2"></span>
                <span class="text-white text-sm">Negative</span>
              </div>
              <div class="flex items-center">
                <span
                  class="w-3 h-3 bg-custom-bg-green rounded-full mr-2"
                ></span>
                <span class="text-white text-sm">Positive</span>
              </div>
            </div>
          </div>

          <!-- Users Table -->
          <div class="overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="text-gray-300 text-sm">
                  <th class="p-4 text-left">Username</th>
                  <th
                    v-for="currency in currencies"
                    :key="currency"
                    class="p-4 text-right"
                  >
                    {{ currency }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-white">
                <tr
                  v-for="user in childrenUsers"
                  :key="user.id"
                  class="border-t border-custom-black"
                >
                  <td class="p-4">
                    <router-link
                      :to="{ name: 'UserDetails', params: { id: user.id } }"
                      class="text-blue-500 hover:underline"
                    >
                      {{ user.username }}
                    </router-link>
                  </td>
                  <td
                    v-for="currency in currencies"
                    :key="currency"
                    class="p-4 text-right"
                  >
                    <div
                      :class="
                        getUserBalance(user, currency) < 0
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      {{ formatNumber(getUserBalance(user, currency)) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import MainLayout from "../layouts/MainLayout.vue";

const store = useStore();

const sports = computed(() => store.state.sports || []);

const error = ref(null);
const isDataReady = ref(false);

const userData = computed(() => {
  const user = store.getters.user || {};
  console.log("User data:", user);
  return user;
});

const headerText = computed(() => {
  const role = userData.value.role;
  switch (role) {
    case 'admin':
      return 'Seniors List';
    case 'senior':
      return 'Managers List';
    case 'manager':
      return 'Agents List';
    case 'agent':
      return 'Users List';
    default:
      return 'Users List';
  }
});

const currencies = computed(() => {
  if (!userData.value) return [];
  const allCurrencies = new Set();
  userData.value.wallets?.forEach((wallet) =>
    allCurrencies.add(wallet.currency.code)
  );
  userData.value.children?.forEach((child) => {
    child.wallets?.forEach((wallet) => allCurrencies.add(wallet.currency.code));
  });
  console.log("Currencies:", Array.from(allCurrencies));
  return Array.from(allCurrencies);
});

const childrenUsers = computed(() => {
  if (!userData.value || !userData.value.children) return [];
  console.log("Children users:", userData.value.children);
  return userData.value.children;
});

const getUserBalance = (user, currencyCode) => {
  if (!user || !user.wallets) return 0;
  const wallet = user.wallets.find((w) => w.currency.code === currencyCode);
  return wallet ? parseFloat(wallet.balance) : 0;
};

const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

const fetchUserData = async () => {
  try {
    await store.dispatch("fetchUserData");
    isDataReady.value = true;
  } catch (e) {
    console.error("Error fetching user data:", e);
    error.value = "Failed to load user data. Please try again.";
  }
};

onMounted(() => {
  console.log("Component mounted");
  fetchUserData();
});
</script>

<style scoped>
/* Add any additional styles here */
</style>