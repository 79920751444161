import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import './assets/app.css';
import 'remixicon/fonts/remixicon.css';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'qwerty123',
    wsHost: 'ws.working-scripts.com',
    wsPort: 6001,
    wssPort: 443,
    forceTLS: false,
    disableStats: true,
    cluster: 'mt1',
    auth: { headers: { Authorization: 'Bearer sometoken' } },
    authEndpoint: 'http://ws.working-scripts.com/api/broadcasting/auth',
});

if (window.Echo.connector.pusher) {
    window.Echo.connector.pusher.connection.bind('state_change', (states) => {
        console.log('Connection state changed:', states);
    });

    window.Echo.connector.pusher.connection.bind('connected', () => {
        console.log('Connected to WebSockets successfully');
    });
} else {
    console.warn('Pusher connection is not initialized');
}

try {
    const channel = window.Echo.channel('live-matches');
    console.log('Subscribed to the channel: live-matches');

    channel.listen('.LiveMatchUpdated', (e) => {
        console.log('Live match updated:', e);
        store.dispatch('updateLiveMatch', e);
    });
} catch (error) {
    console.error('Error subscribing to the channel:', error);
}

// Initialize WebSocket in Vuex store
// store.dispatch('initWebSocket');

const app = createApp(App);
app.use(store).use(router).mount("#app");