<template>
  <div class="fixed inset-0 z-50 flex flex-col">
    <!-- Header space (transparent to show the actual header behind) -->
    <div class="h-[40px]"></div>
    
    <!-- Login form container -->
    <div class="relative flex-grow flex items-start justify-center bg-black bg-opacity-80">
      <div class="bg-gray-100 w-80 shadow-lg mt-0">
        <!-- Form -->
        <form @submit.prevent="handleSubmit" class="relative p-6">
          <!-- Close button -->
          <button
            @click="closeModal"
            class="absolute right-0 top-0 bg-emerald-600 text-white p-2 hover:bg-emerald-700 rounded-bl"
          >
            <i class="ri-close-line"></i>
          </button>

          <div class="space-y-4">
            <div>
              <input
                v-model="email"
                type="text"
                placeholder="Username"
                class="w-full p-2 border border-gray-300 bg-white focus:outline-none focus:border-emerald-600"
              />
            </div>

            <div>
              <input
                v-model="password"
                type="password"
                placeholder="Password"
                class="w-full p-2 border border-gray-300 bg-white focus:outline-none focus:border-emerald-600"
              />
            </div>

            <div class="flex items-center">
              <input
                v-model="rememberMe"
                type="checkbox"
                id="save-password"
                class="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
              />
              <label for="save-password" class="ml-2 text-sm text-gray-600">
                Save Password
              </label>
            </div>

            <button
              type="submit"
              class="w-full bg-emerald-600 text-white p-2 hover:bg-emerald-700 focus:outline-none"
              :disabled="isLoading"
            >
              {{ isLoading ? "Loading..." : "Login" }}
            </button>

            <div class="text-center">
              <a href="#" class="text-sm text-gray-600 hover:text-emerald-600">
                Lost Login?
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["close", "login-success"]);
const store = useStore();

const email = ref("");
const password = ref("");
const rememberMe = ref(false);
const isLoading = ref(false);

const handleSubmit = async () => {
  try {
    isLoading.value = true;
    await store.dispatch("login", {
      email: email.value,
      password: password.value,
      rememberMe: rememberMe.value
    });
    emit("login-success");
    closeModal();
  } catch (error) {
    console.error("Login failed:", error);
  } finally {
    isLoading.value = false;
  }
};

const closeModal = () => {
  emit("close");
  email.value = "";
  password.value = "";
  rememberMe.value = false;
};
</script>