import axios from 'axios'

const STORAGE_KEY = 'forbet_auth'

// Helper function to get initial state from localStorage
const getInitialState = () => {
  if (typeof window === 'undefined') return {}
  const savedState = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
  if (savedState.tokenExpiration && new Date(savedState.tokenExpiration) < new Date()) {
    // Token has expired, clear the saved state
    localStorage.removeItem(STORAGE_KEY)
    return {}
  }
  return savedState
}

export const state = () => ({
  accessToken: null,
  tokenType: null,
  user: null,
  tokenExpiration: null,
  ...getInitialState()
})

export const mutations = {
  SET_AUTH_DATA(state, { access_token, token_type }) {
    state.accessToken = access_token
    state.tokenType = token_type
    state.tokenExpiration = new Date(Date.now() + 60 * 60 * 1000).toISOString() // 60 minutes from now
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      accessToken: state.accessToken,
      tokenType: state.tokenType,
      tokenExpiration: state.tokenExpiration
    }))
  },
  SET_USER(state, user) {
    state.user = user
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      ...JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}'),
      user
    }))
  },
  CLEAR_AUTH_DATA(state) {
    state.accessToken = null
    state.tokenType = null
    state.user = null
    state.tokenExpiration = null
    localStorage.removeItem(STORAGE_KEY)
  }
}

export const actions = {
  async login({ commit, dispatch }, credentials) {
    try {
      const response = await axios.post('https://api.newbet365.org/api/user/login', credentials)
      commit('SET_AUTH_DATA', response.data)
      await dispatch('fetchUser')
      dispatch('setupTokenRefresh')
    } catch (error) {
      console.error('Login failed:', error)
      throw error
    }
  },
  async fetchUser({ commit, state }) {
    try {
      const response = await axios.get('https://api.newbet365.org/api/user', {
        headers: { Authorization: `${state.tokenType} ${state.accessToken}` }
      })
      commit('SET_USER', response.data.user)
      console.log('Fetched user data from auth store:', response.data.user)
    } catch (error) {
      console.error('Fetching user failed:', error)
      throw error
    }
  },
  setupTokenRefresh({ dispatch, state }) {
    if (state.tokenExpiration) {
      const timeUntilExpiry = new Date(state.tokenExpiration) - new Date()
      setTimeout(() => {
        dispatch('refreshToken')
      }, timeUntilExpiry)
    }
  },
  async refreshToken({ commit, dispatch }) {
    try {
      const response = await axios.post('https://api.newbet365.org/api/user/refresh-token')
      commit('SET_AUTH_DATA', response.data)
      await dispatch('fetchUser')  // Add this line to fetch user data after token refresh
      dispatch('setupTokenRefresh')
    } catch (error) {
      console.error('Token refresh failed:', error)
      commit('CLEAR_AUTH_DATA')
    }
  },
  logout({ commit }) {
    commit('CLEAR_AUTH_DATA')
  },
  checkAuth({ state, commit, dispatch }) {
    if (state.tokenExpiration && new Date(state.tokenExpiration) < new Date()) {
      commit('CLEAR_AUTH_DATA')
    } else if (state.accessToken) {
      dispatch('setupTokenRefresh')
      dispatch('fetchUser')  // Add this line to fetch user data when checking auth
    }
  }
}

export const getters = {
  isAuthenticated: state => !!state.accessToken,
  user: state => state.user || {},  // Return an empty object if user is null
  authHeader: state => state.accessToken ? `${state.tokenType} ${state.accessToken}` : null
}