<template>
  <div class="min-h-screen bg-gray-100">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <SportsList :sports="sports" />
        <FeaturedLeague :league="featuredLeague" :sportId="sportId"/>
        <TodaysMatches :matches="todaysMatches" :sportId="sportId"/>
        <LiveInPlay :matches="liveMatches" />
        <UpcomingMatches :matches="upcomingMatches" :sportId="sportId"/>

      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import MainLayout from "../layouts/MainLayout.vue";
import SportsList from "../components/SportsList.vue";
import FeaturedLeague from "../components/FeaturedLeague.vue";
import TodaysMatches from "../components/TodaysMatches.vue";
import LiveInPlay from "../components/LiveInPlay.vue";
import UpcomingMatches from "../components/UpcomingMatches.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";

const store = useStore();

const sports = computed(() => store.state.sports || []);
const todaysMatches = computed(() => store.state.todaysMatches || []);
const liveMatches = computed(() => store.state.liveMatches || []);
const upcomingMatches = computed(() => store.state.upcomingMatches || []);
const featuredLeague = computed(() => store.state.featuredLeague || []);
const isLoading = computed(() => store.state.isLoading);
const sportId = computed(() => store.state.currentSportId);

console.log("current sport ID:", sportId.value);

console.log("Todays matches HOMEVIEW:", todaysMatches.value);

onMounted(async () => {
  console.log("HomeView is mounted, about to fetch data.");
  try {
    await store.dispatch("fetchHomePageData");
    console.log("Home page data fetched successfully.");
  } catch (error) {
    console.error("Failed to fetch home page data:", error);
  }
});
</script>