<script setup>
import { ref, computed, defineProps, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { format } from 'date-fns';

const props = defineProps({
  matches: {
    type: Array,
    required: true
  },
  sportId: {
    type: Number,
    required: true
  }
});

const router = useRouter();
const store = useStore();
const selectedOdds = ref({});

onMounted(() => {
  updateSelectedOddsFromBetslip();
});

const updateSelectedOddsFromBetslip = () => {
  const storedSelections = store.state.betslip.selections;
  const newSelectedOdds = {};
  storedSelections.forEach(selection => {
    newSelectedOdds[selection.matchId] = selection.oddId;
  });
  selectedOdds.value = newSelectedOdds;
};

watch(() => store.state.betslip.selections, () => {
  updateSelectedOddsFromBetslip();
}, { deep: true });

const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    return format(new Date(dateString), 'HH:mm dd MMM');
  } catch (error) {
    console.error("Error formatting date:", error);
    return '';
  }
};

const getMarketOdds = (markets) => {
  if (!markets || !Array.isArray(markets) || markets.length === 0) return [];
  
  let targetMarket;
  let oddNames;

  switch (props.sportId) {
    case 1: // Soccer
      targetMarket = markets.find(market => market.market && market.market.key === "full_time_result");
      oddNames = null; // Return all odds for soccer
      break;
    case 18: // Basketball
    case 12: // American Football
      targetMarket = markets.find(market => market.market && market.market.key === "game_lines");
      oddNames = ["Money Line"];
      break;
    case 8: // Rugby Union
    case 19: // Rugby League
      targetMarket = markets.find(market => market.market && market.market.key === "game_betting_2_way");
      oddNames = ["To Win"];
      break;
    case 9: // Boxing
      targetMarket = markets.find(market => market.market && market.market.key === "to_win_fight");
      oddNames = null; // Return all odds for boxing
      break;
    case 13: // Tennis
      targetMarket = markets.find(market => market.market && market.market.key === "to_win_match");
      oddNames = null; // Return all odds for tennis
      break;
    case 78: // Handball
    case 91: // Volleyball
      targetMarket = markets.find(market => market.market && market.market.key === "game_lines");
      oddNames = ["To Win", "Winner"];
      break;
    case 151: // E-sports
      targetMarket = markets.find(market => market.market && market.market.key === "match_lines");
      oddNames = ["To Win"];
      break;
    default:
      targetMarket = markets[0];
      oddNames = ["To Win", "Winner"];
  }
  
  if (targetMarket && targetMarket.odds) {
    if (oddNames) {
      return targetMarket.odds.filter(odd => oddNames.includes(odd.name));
    } else {
      return targetMarket.odds;
    }
  }
  
  return [];
};

const navigateToMatchDetail = (matchId) => {
  router.push(`/matchdetail/${matchId}`);
};

const toggleOddSelection = (matchId, oddId, odd) => {
  console.log('odd selected:', odd);
  const match = props.matches.find(m => m.id === matchId);
  if (!match) return;

  // Use the market ID directly from the odd object
  const marketId = odd.market_id;

  store.dispatch('betslip/addOrRemoveSelection', {
    matchId: matchId,
    oddId: oddId,
    matchName: `${match.homeTeamName} vs ${match.awayTeamName}`,
    market: odd.name,
    odds: odd.odds,
    marketId: marketId // Use the correct market ID from the odd object
  });

  // Update local state
  const newSelectedOdds = { ...selectedOdds.value };
  if (newSelectedOdds[matchId] === oddId) {
    delete newSelectedOdds[matchId];
  } else {
    newSelectedOdds[matchId] = oddId;
  }
  selectedOdds.value = newSelectedOdds;

  // Toggle betslip visibility
  const hasSelections = store.getters['betslip/selectionCount'] > 0;
  store.dispatch('betslip/toggleVisibility', hasSelections);
};

const isOddSelected = (matchId, oddId) => {
  return selectedOdds.value[matchId] === oddId;
};

const isOddSuspended = (odd) => {
  return !odd || odd.is_suspended || odd.odds === null || odd.odds === undefined;
};

const sportName = computed(() => {
  const sportNames = {
    1: 'Soccer',
    13: 'Tennis',
    91: 'Volleyball',
    18: 'Basketball',
    12: 'American Football',
    78: 'Handball',
    8: 'Rugby Union',
    19: 'Rugby League',
    9: 'Boxing',
    151: 'E-sports'
  };
  return sportNames[props.sportId] || 'Sport';
});

const oddColumns = computed(() => {
  if (props.sportId === 1) {
    return ['1', 'X', '2'];
  } else {
    return ['1', '2'];
  }
});

const sortedOdds = (odds) => {
  if (!odds) return [];
  if (props.sportId === 1) {
    // For soccer, sort by name to maintain 1-X-2 order
    return [...odds].sort((a, b) => {
      const order = ["1", "X", "2"];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });
  } else if ([9, 13, 151].includes(props.sportId)) {
    // For boxing, tennis, and e-sports, no specific sorting
    return odds;
  } else {
    // For other sports, ensure home team (1) comes first
    return odds.sort((a, b) => {
      if (a.header && b.header) {
        if (a.header.includes('1') || a.header.toLowerCase().includes('home')) return -1;
        if (b.header.includes('1') || b.header.toLowerCase().includes('home')) return 1;
      }
      return 0;
    });
  }
};
</script>

<template>
  <div class="max-w-full mx-auto bg-custom-bg-4 text-white">
    <!-- Header -->
    <div class="bg-custom-bg-green p-1">
      <div class="text-sm">{{ sportName }}</div>
      <div class="font-bold">Upcoming Matches</div>
    </div>
  
    <!-- Matches list -->
    <div class="bg-custom-bg-3 flex items-center text-sm text-custom-black">
      <div class="w-1/2 sm:w-2/3 p-1">Teams</div>
      <div class="w-1/2 sm:w-1/3 flex">
        <span v-for="column in oddColumns" 
              :key="column" 
              class="flex-1 p-1 text-center border-l border-custom-bg-4">
          {{ column }}
        </span>
      </div>
    </div>
  
    <div v-for="match in matches" :key="match.id" class="flex items-stretch border-b border-custom-bg-3 hover:bg-custom-bg-3">
      <div 
        class="w-1/2 sm:w-2/3 p-0 px-1 leading-tight cursor-pointer"
        @click="navigateToMatchDetail(match.id)"
      >
        <div>
          <div class="text-sm sm:text-base">{{ match.homeTeamName }}</div>
          <div class="text-sm sm:text-base">{{ match.awayTeamName }}</div>
        </div>
        <div class="text-xs sm:text-sm text-custom-white mt-1">{{ formatDate(match.starts_at) }}</div>
      </div>
      <div class="w-1/2 sm:w-1/3 flex">
        <template v-for="(odd, index) in sortedOdds(getMarketOdds(match.markets))" :key="index">
          <div 
            class="flex-1 relative border-l border-custom-bg-3 cursor-pointer transition-colors duration-200 bg-custom-black"
            :class="{
              'bg-white': isOddSelected(match.id, odd.id) && !isOddSuspended(odd)
            }"
            @click="!isOddSuspended(odd) && toggleOddSelection(match.id, odd.id, odd)"
          >
            <div class="absolute inset-0 flex items-center justify-center">
              <template v-if="!isOddSuspended(odd)">
                <span 
                  class="text-sm sm:text-base font-semibold"
                  :class="isOddSelected(match.id, odd.id) ? 'text-custom-green' : 'text-yellow-300'"
                >
                  {{ odd.odds.toFixed(2) }}
                </span>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  
    <!-- Show All Events -->
    <div class="bg-custom-bg-3 flex justify-between items-center p-2 cursor-pointer hover:bg-custom-bg-4 text-custom-white">
      <span class="text-sm">Show All Events</span>
      <span class="text-sm">{{ matches.length }} Events</span>
    </div>
  </div>
</template>

<style scoped>
.font-thin {
  font-weight: 100;
}
.font-light {
  font-weight: 300;
}
</style>