<template>
  <div class="w-full">
    <!-- Loading state -->
    <div v-if="loading" class="p-4 text-center">
      <p>Loading...</p>
    </div>

    <!-- Error state -->
    <div v-else-if="error" class="p-4 text-center text-red-500">
      <p>{{ error }}</p>
    </div>

    <!-- No matches state -->
    <div v-else-if="!matches || matches.length === 0" class="p-4 text-center">
      <p>No matches available for today.</p>
    </div>

    <!-- Content -->
    <template v-else>
      <div class="text-white p-2 bg-custom-bg-green">
        <div class="text-sm">{{ sportName }}</div>
        <div class="font-bold">Today's Matches</div>
      </div>
      <div v-for="(matches, date) in groupedMatches" :key="date" class="mb-4">
        <div class="text-white p-2 bg-custom-bg-3">
          <span class="font-light">{{ format(parseISO(date), 'EEEE, MMMM d, yyyy') }}</span>
        </div>
        <div class="overflow-x-auto scrollbar-hide">
          <div class="flex space-x-0 p-0">
            <div
              v-for="match in matches"
              :key="match.id"
              class="flex-shrink-0 shadow px-1 py-2 w-48 bg-custom-bg-4"
            >
              <div @click="navigateToMatchDetail(match.id)" class="cursor-pointer">
                <div class="text-sm text-white font-thin truncate mb-1">
                  {{ match.homeTeamName }}
                </div>
                <div class="text-sm text-white font-thin truncate mb-1">
                  {{ match.awayTeamName }}
                </div>
                <div class="text-xs text-custom-white mt-1 font-light">
                  {{ formatDate(match.starts_at) }}
                </div>
              </div>
              <div class="flex w-full mt-2 bg-custom-bg-3">
                <button
                  v-for="(odd, index) in getMarketOdds(match.markets)"
                  :key="odd.id"
                  @click="toggleOddSelection(match.id, odd.id, odd, match)"
                  :disabled="isOddSuspended(odd, match.markets.find(m => m.id === odd.match_market_id))"
                  :class="[
                    'flex-1 flex items-center justify-center py-3 text-base font-light transition-colors duration-200',
                    { 'border-l border-r border-custom-bg-4': index === 1 && sportId === 1 },
                    { 'border-r border-custom-bg-4': index === 0 && [13, 91, 18, 12, 78, 17, 19, 9, 8, 151, 92].includes(sportId) },
                    isOddSelected(match.id, odd.id, odd.match_market_id) ? 'bg-white' : '',
                    isOddSuspended(odd, match.markets.find(m => m.id === odd.match_market_id)) ? 'opacity-50 cursor-not-allowed' : ''
                  ]"
                >
                  <span 
                    class="mr-1 text-xs md:text-sm"
                    :class="isOddSelected(match.id, odd.id, odd.match_market_id) ? 'text-custom-bg-4' : 'text-white'"
                  >
                    {{ getOddLabel(odd, index) }}
                  </span>
                  <span 
                    v-if="!isOddSuspended(odd, match.markets.find(m => m.id === odd.match_market_id))"
                    :class="['text-xs md:text-sm', isOddSelected(match.id, odd.id, odd.match_market_id) ? 'text-custom-green' : 'text-custom-yellow']"
                  >
                    {{ odd.odds ? odd.odds.toFixed(2) : 'N/A' }}
                  </span>
                  <i 
                    v-else
                    class="ri-lock-2-line text-xs md:text-sm text-gray-400"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted, watch } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { format, parseISO } from "date-fns";

const props = defineProps({
  matches: {
    type: Array,
    default: () => []
  },
  sportId: {
    type: Number,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: null
  }
});

const router = useRouter();
const store = useStore();
const selectedOdds = ref({});

onMounted(() => {
  updateSelectedOddsFromBetslip();
});

const updateSelectedOddsFromBetslip = () => {
  const storedSelections = store.state.betslip.selections;
  const newSelectedOdds = {};
  storedSelections.forEach(selection => {
    if (!newSelectedOdds[selection.matchId]) {
      newSelectedOdds[selection.matchId] = {};
    }
    newSelectedOdds[selection.matchId][selection.marketId] = selection.oddId;
  });
  selectedOdds.value = newSelectedOdds;
};

watch(() => store.state.betslip.selections, (newSelections) => {
  const newSelectedOdds = {};
  newSelections.forEach(selection => {
    if (!newSelectedOdds[selection.matchId]) {
      newSelectedOdds[selection.matchId] = {};
    }
    newSelectedOdds[selection.matchId][selection.marketId] = selection.oddId;
  });
  selectedOdds.value = newSelectedOdds;
}, { deep: true });

const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    return format(parseISO(dateString), "HH:mm");
  } catch (error) {
    console.error("Error formatting date:", error);
    return '';
  }
};

const getMarketOdds = (markets) => {
  if (!markets || typeof markets !== 'object') return [];
  
  const marketArray = Array.isArray(markets) ? markets : [markets];
  let targetMarket;

  if (props.sportId === 1) {
    targetMarket = marketArray.find(
      (market) => market.market && market.market.key === "full_time_result"
    );
  } else if (props.sportId === 13) {
    targetMarket = marketArray.find(
      (market) => market.market && market.market.key === "to_win_match"
    );
  } else if ([91, 18, 12, 78, 17].includes(props.sportId)) {
    targetMarket = marketArray.find(
      (market) => market.market && market.market.key === "game_lines"
    );
  } else if ([19, 8].includes(props.sportId)) {
    targetMarket = marketArray.find(
      (market) => market.market && market.market.key === "game_betting_2_way"
    );
  } else if (props.sportId === 9) {
    targetMarket = marketArray.find(
      (market) => market.market && market.market.key === "to_win_fight"
    );
  } else if ([151, 92].includes(props.sportId)) {
    targetMarket = marketArray.find(
      (market) => market.market && market.market.key === "match_lines"
    );
  }
  
  if (targetMarket) {
    if (props.sportId === 91) {
      return targetMarket.odds.filter(odd => odd.name === "Winner");
    } else if ([18, 12, 17, 19, 8, 151, 92].includes(props.sportId)) {
      return targetMarket.odds.filter(odd => odd.name === "To Win");
    } else if (props.sportId === 78) {
      return targetMarket.odds.filter(odd => odd.name === "To Win");
    } else if (props.sportId === 9) {
      return targetMarket.odds;
    }
    return targetMarket.odds;
  }
  
  return [];
};

const navigateToMatchDetail = (matchId) => {
  router.push(`/matchdetail/${matchId}`);
};

const toggleOddSelection = (matchId, oddId, odd, match) => {
  console.log('odd selected', odd)
  const newSelectedOdds = { ...selectedOdds.value };
  if (!newSelectedOdds[matchId]) {
    newSelectedOdds[matchId] = {};
  }

  const currentlySelectedOddId = newSelectedOdds[matchId][odd.market_id];
  const isCurrentlySelected = currentlySelectedOddId === oddId;

  if (isCurrentlySelected) {
    // Deselect the odd
    newSelectedOdds[matchId][odd.match_market_id] = null;
    store.dispatch('betslip/addOrRemoveSelection', { matchId, marketId: odd.market_id });
  } else {
    // If there's a previously selected odd in this market, remove it first
    if (currentlySelectedOddId) {
      store.dispatch('betslip/addOrRemoveSelection', { matchId, marketId: odd.market_id });
    }
    
    // Select the new odd for this market
    newSelectedOdds[matchId][odd.match_market_id] = oddId;
    store.dispatch('betslip/addOrRemoveSelection', {
      matchId,
      marketId: odd.market_id,
      oddId,
      matchName: `${match.homeTeamName} vs ${match.awayTeamName}`,
      market: odd.name,
      odds: odd.odds
    });
  }

  // Update the local state immediately
  selectedOdds.value = newSelectedOdds;

  // Check if there are any active selections for this match
  const hasAnySelections = Object.values(newSelectedOdds[matchId]).some(value => value !== null);
  store.dispatch('betslip/toggleVisibility', hasAnySelections);
};

const isOddSelected = (matchId, oddId, marketId) => {
  return selectedOdds.value[matchId]?.[marketId] === oddId;
};

const getOddLabel = (odd, index) => {
  if (props.sportId === 1) {
    return odd.name === "Draw" ? "X" : index === 0 ? "1" : "2";
  } else if ([13, 91, 18, 12, 78, 17, 19, 9, 8, 151, 92].includes(props.sportId)) {
    return index === 0 ? "1" : "2";
  }
  return "";
};

const isOddSuspended = (odd, market) => {
  return !odd.odds || odd.is_suspended || market.is_suspended;
};

const sportName = computed(() => {
  const sportNames = {
    1: 'Football',
    13: 'Tennis',
    91: 'Volleyball',
    18: 'Basketball',
    12: 'American Football',
    78: 'Handball',
    17: 'Ice Hockey',
    19: 'Rugby League',
    9: 'Boxing',
    8: 'Rugby Union',
    151: 'E-sports',
    92: 'Table Tennis'
  };
  return sportNames[props.sportId] || 'Sport';
});

const groupedMatches = computed(() => {
  const groups = {};
  if (Array.isArray(props.matches)) {
    props.matches.forEach(match => {
      if (match && match.starts_at) {
        const date = format(parseISO(match.starts_at), 'yyyy-MM-dd');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(match);
      }
    });
  }
  return groups;
});
</script>

<style scoped>
.font-thin {
  font-weight: 100;
}
.font-light {
  font-weight: 300;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cursor-pointer {
  cursor: pointer;
}
</style>