<template>
  <div class="min-h-screen bg-custom-black">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <div class="bg-custom-black min-h-screen">
          <!-- Header -->
          <div class="p-0">
            <div class="flex justify-between items-center mb-6">
              <div class="flex items-between">
                <button @click="goBack" class="text-gray-400 mr-2">
                  <ChevronLeftIcon class="h-6 w-6" />
                </button>
                <h2 class="text-xl font-semibold text-white">My Bets</h2>
              </div>
            </div>

            <!-- Date Range Picker -->
            <div class="flex justify-center gap-2 mb-6 max-w-3xl mx-auto">
              <input
                type="date"
                v-model="dateRange.start"
                class="w-full md:w-1/3 bg-custom-black-2 px-4 py-2 text-gray-300 border border-gray-700"
              />
              <input
                type="date"
                v-model="dateRange.end"
                class="w-full md:w-1/3 bg-custom-black-2 px-4 py-2 text-gray-300 border border-gray-700"
              />
              <button
                @click="applyDateFilter"
                class="bg-custom-bg-green text-white px-6 py-2 hover:bg-custom-green transition-colors"
              >
                Filtro
              </button>
            </div>

            <!-- Tabs -->
            <div
              class="flex justify-center space-x-4 mb-6 border-b border-gray-700"
            >
              <button
                v-for="tab in tabs"
                :key="tab.value"
                @click="currentTab = tab.value"
                :class="[
                  'pb-2 px-4 text-gray-300',
                  currentTab === tab.value
                    ? 'text-white border-b-2 border-red-500 -mb-[1px]'
                    : 'hover:text-white',
                ]"
              >
                {{ tab.label }}
              </button>
            </div>

            <!-- Currency Selector -->
            <div class="flex justify-center gap-2 mb-6">
              <button
                v-for="currency in currencies"
                :key="currency"
                @click="selectedCurrency = currency"
                :class="[
                  'px-4 py-2 text-gray-300',
                  selectedCurrency === currency
                    ? 'bg-gray-700'
                    : 'bg-custom-black-2 hover:bg-gray-700',
                ]"
              >
                {{ currency }}
              </button>
            </div>

            <!-- Users List -->
            <div class="w-full">
              <div
                v-if="isLoadingUserDetails"
                class="flex justify-center items-center py-4"
              >
                <SimpleSpinner />
              </div>
              <div
                v-for="user in currentUsers"
                :key="user.id"
                class="border-b border-white/50"
              >
                <div
                  v-if="user.role !== 'user'"
                  @click="handleUserClick(user)"
                  :class="[
                    'flex justify-between items-center py-3 px-4 bg-custom-bg-green',
                    {
                      'cursor-pointer hover:bg-opacity-90':
                        !isLoadingUserDetails,
                    },
                    { 'cursor-not-allowed': isLoadingUserDetails },
                  ]"
                >
                  <div class="flex items-center gap-4">
                    <span class="text-white">{{ user.username }}</span>
                    <span class="text-white font-light"
                      >({{ user.bet_stats.total_bets }})</span
                    >
                  </div>
                  <span class="text-white font-light">{{
                    formatNumber(user.bet_stats.total_stake)
                  }}</span>
                </div>

                <!-- User Bets -->
                <div v-if="user.role === 'user'" class="bg-custom-green">
                  <div
                    v-for="bet in user.bets"
                    :key="bet.id"
                    class="border-b border-gray-700/30"
                  >
                    <!-- Bet Header -->
                    <div
                      class="flex items-center justify-between py-3 px-4 bg-custom-bg-green cursor-pointer hover:bg-opacity-90"
                      @click="toggleBet(bet)"
                    >
                      <div class="flex items-center justify-between w-full">
                        <div class="flex items-center space-x-3">
                          <span class="text-white text-sm"
                            >{{ user.username }}{{ bet.id }}</span
                          >
                          <span class="text-gray-400 text-xs"
                            >{{ formatTime(bet.created_at) }} - N°{{
                              bet.id
                            }}</span
                          >
                        </div>
                        <div class="flex items-center space-x-3">
                          <span
                            :class="[
                              'text-sm font-medium',
                              getBetStatusColor(bet.status),
                            ]"
                          >
                            {{ capitalizeFirst(bet.status) }}
                          </span>
                          <span class="text-white text-sm"
                            >{{ formatNumber(bet.stake) }} LEK</span
                          >
                          <ChevronDownIcon
                            :class="[
                              'h-5 w-5 text-gray-400 transition-transform',
                              bet.showSelections ? 'transform rotate-180' : '',
                            ]"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Selections Accordion -->
                    <div v-show="bet.showSelections" class="bg-custom-bg-4 py-2">
                      <div
                        v-for="selection in bet.selections"
                        :key="selection.id"
                        class="px-2 py-2"
                      >
                        <div
                          class="flex items-center justify-between cursor-pointer"
                          @click="toggleSelection(selection)"
                        >
                          <div class="flex items-center gap-3">
                            <div
                              class="w-2 h-2 rounded-full bg-red-500 mt-1"
                            ></div>
                            <div class="flex flex-col">
                              <span class="text-white text-sm">
                                {{ selection.match.home.name }} -
                                {{ selection.match.away.name }}
                              </span>
                              <span class="text-gray-400 text-xs mt-1">{{
                                selection.name
                              }}</span>
                            </div>
                          </div>
                          <div class="flex items-center space-x-3">
                            <span class="text-white text-sm">{{
                              selection.odds
                            }}</span>
                            <ChevronDownIcon
                              :class="[
                                'h-5 w-5 text-gray-400 transition-transform',
                                selection.showEvents
                                  ? 'transform rotate-180'
                                  : '',
                              ]"
                            />
                          </div>
                        </div>
                        <div class="flex justify-between mt-4 px-5">
                          <div class="flex flex-col">
                            <span class="text-gray-400 text-xs">Amount</span>
                            <span class="text-white text-sm"
                              >{{ formatNumber(bet.stake) }} LEK</span
                            >
                          </div>
                          <div class="flex flex-col items-end">
                            <span class="text-gray-400 text-xs">To Return</span>
                            <span class="text-white text-sm"
                              >{{
                                formatNumber(bet.stake * selection.odds)
                              }}
                              LEK</span
                            >
                          </div>
                        </div>

                        <div v-if="selection.showEvents">
                          <MatchTimeline
                            :events="selection.match.stats.events"
                            :homeTeam="selection.match.home.name"
                            :awayTeam="selection.match.away.name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Summary Row -->
                  <div
                    class="flex justify-between items-center py-3 px-4 bg-[#2A2A3C] border-t border-gray-700/30"
                  >
                    <span class="text-gray-300 text-sm">All</span>
                    <span class="text-gray-300 text-sm"
                      >{{ formatNumber(getTotalStake(user)) }} LEK</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { ChevronLeftIcon, ChevronDownIcon } from "lucide-vue-next";
import MainLayout from "../layouts/MainLayout.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";
import MatchTimeline from "../components/MatchTimeline.vue";
import axios from "axios";

const store = useStore();
const isLoading = computed(() => store.state.isLoading);
const sports = computed(() => store.state.sports || []);
const user = computed(() => store.getters["user"]);
// console.log("User in MyBetsView:", user.value)
const currentTab = ref("sports");
const selectedCurrency = ref("EUR");
const currentUsers = ref([]);
const userStack = ref([]);
const isLoadingUserDetails = ref(false);
const toggleSelection = (selection) => {
  selection.showEvents = !selection.showEvents;
};

const dateRange = ref({
  start: new Date().toISOString().split("T")[0],
  end: new Date().toISOString().split("T")[0],
});

const tabs = [
  { label: "Sports", value: "sports" },
  { label: "Virtual Soccer", value: "virtual-soccer" },
  { label: "Virtual Games", value: "virtual-games" },
];

const currencies = ["EUR", "USD", "LEK"];

const getAuthToken = () => {
  const authData = JSON.parse(localStorage.getItem("forbet_auth") || "{}");
  return authData.accessToken;
};

const handleUserClick = (user) => {
  if (user.role !== "user") {
    fetchUserDetails(user.id);
  }
};

const toggleBet = (bet) => {
  bet.showSelections = !bet.showSelections;
};

const fetchUserDetails = async (userId) => {
  const token = getAuthToken();
  if (!token) {
    console.error("No auth token found");
    return;
  }

  console.log("Fetching user details for:", userId);
  isLoadingUserDetails.value = true;
  try {
    const response = await axios.get(
      `https://api.newbet365.org/api/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (currentUsers.value.length > 0) {
      userStack.value.push([...currentUsers.value]);
    }

    console.log("Fetched user details FROM API:", response.data);
    currentUsers.value = response.data || [];
  } catch (error) {
    console.error("Failed to fetch user details:", error);
  } finally {
    isLoadingUserDetails.value = false;
  }
};

const goBack = () => {
  if (userStack.value.length > 0) {
    currentUsers.value = userStack.value.pop();
  }
};

const applyDateFilter = () => {
  console.log("Applying date filter:", dateRange.value);
};

const formatNumber = (value) => {
  if (!value) return "0.00";
  return parseFloat(value).toFixed(2);
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

const getBetStatusColor = (status) => {
  switch (status) {
    case "won":
      return "text-green-500";
    case "lost":
      return "text-red-500";
    default:
      return "text-yellow-500";
  }
};

const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getTotalStake = (user) => {
  return user.bets.reduce((total, bet) => total + parseFloat(bet.stake), 0);
};

onMounted(async () => {
  try {
    await store.dispatch("fetchSports");
    currentUsers.value = user.value.children || [];
  } catch (error) {
    console.error("Failed to fetch initial data:", error);
  }
});
</script>

<style scoped>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.relative {
  position: relative;
  z-index: 20;
}
</style>

