<template>
<div v-if="isMatchDataReady" class="bg-custom-bg-2 text-white">
  <!-- Header -->
  <div class="flex items-center justify-between p-1">
    <button class="p-2" @click="router.back()">
      <i class="ri-arrow-left-s-line text-2xl"></i>
    </button>
    <div class="flex-1 text-center">
      <div class="flex items-center justify-center space-x-4">
        <span class="text-sm">{{ match.homeTeamName }}</span>
        <div class="flex items-center space-x-2">
          <span class="text-yellow-400">{{ match.homeTeamScore }}</span>
          <span>-</span>
          <span class="text-yellow-400">{{ match.awayTeamScore }}</span>
        </div>
        <span class="text-sm">{{ match.awayTeamName }}</span>
      </div>
    </div>
    <button class="p-2">
      <i class="ri-arrow-down-s-line text-2xl"></i>
    </button>
  </div>

  <!-- Field Icon -->
<div class="flex justify-center py-1 border-b border-custom-bg-gray">
  <div class="w-12 h-6 text-gray-400 mb-1 relative">
    <svg viewBox="0 0 24 16" fill="none" stroke="currentColor" class="w-full h-full">
      <rect x="1" y="1" width="22" height="14" stroke-width="1.5" class="text-custom-white"/>
      <line x1="12" y1="1" x2="12" y2="15" stroke-width="0.75" class="text-custom-white"/>
      <rect x="1" y="4" width="2" height="8" stroke-width="0.75" class="text-custom-white"/>
      <rect x="21" y="4" width="2" height="8" stroke-width="0.75" class="text-custom-white"/>
      <circle cx="12" cy="8" r="1.5" stroke-width="0.75" class="text-custom-white"/>
    </svg>
  </div>
</div>

<div class="flex w-12 h-0.5 bg-gray-700 mx-auto mb-3">
  <div class="w-1/2 mr-px" :style="{ backgroundColor: homeTeamColor }"></div>
  <div class="w-1/2 ml-px" :style="{ backgroundColor: awayTeamColor }"></div>
</div>

  <!-- Navigation Tabs -->
  <div class="flex items-center border-b border-custom-bg-gray">
    <button 
      :class="['px-4 py-2 text-sm font-medium', activeTab === 'live' ? 'border-b-2 border-yellow-400' : 'text-gray-400']"
      @click="activeTab = 'live'"
    >
      Live
    </button>
    <button 
      :class="['px-4 py-2 text-sm font-medium', activeTab === 'timeline' ? 'border-b-2 border-yellow-400' : 'text-gray-400']"
      @click="activeTab = 'timeline'"
    >
      Timeline
    </button>
    <button 
      :class="['px-4 py-2 text-sm font-medium', activeTab === 'stats' ? 'border-b-2 border-yellow-400' : 'text-gray-400']"
      @click="activeTab = 'stats'"
    >
      Stats
    </button>
    <div class="flex-1 flex justify-end px-4">
      <button class="p-2">
        <i class="ri-layout-grid-line text-xl text-gray-400"></i>
      </button>
      <button class="p-2" @click="activeTab = 'stats'">
        <i class="ri-bar-chart-2-line text-xl text-gray-400"></i>
      </button>
    </div>
  </div>

  <!-- Live View -->
  <div v-if="activeTab === 'live'" class="relative mx-4 my-4 rounded overflow-hidden">
    <div class="aspect-[2/1] bg-cover bg-center" style="background-image: url('/img/soccer-pitch.png');">
      <!-- Timer -->
      <div class="absolute top-2 left-1/2 -translate-x-1/2 bg-custom-bg-2 bg-opacity-50 px-2 py-1 rounded text-xs">
        {{ formattedMatchTime }}
      </div>

      <!-- Match Stats -->
      <div class="absolute bottom-2 left-2 right-2 flex justify-between text-xs text-white bg-custom-bg-2 bg-opacity-50 px-2 py-1 rounded">
        <div>Possession: {{ homePossession }}%</div>
        <div>Shots on Target: {{ homeShots }} - {{ awayShots }}</div>
        <div>Possession: {{ awayPossession }}%</div>
      </div>
    </div>
  </div>

  <!-- Timeline View -->
  <div v-else-if="activeTab === 'timeline'" class="p-4 bg-custom-bg-2">
    <!-- Timeline Progress Bar -->
    <div class="relative h-8 mb-4">
      <!-- Base Timeline Bar -->
      <div class="absolute w-full h-2 bg-custom-bg-3 top-1">
        <!-- Time Segments -->
        <template v-for="n in 14" :key="n">
          <div 
            class="absolute w-px h-4 bg-white bg-opacity-20"
            :style="{ left: `${(n * 100) / 14}%`, top: '-4px' }"
          ></div>
        </template>

        <!-- Match Progress (Green) -->
        <div 
          class="absolute h-full bg-[#4CAF50]" 
          :style="{ width: `${(parseInt(match.timer.currentTime) / 90) * 100}%` }"
        ></div>

        <!-- Event Markers -->
        <template v-for="event in matchEvents" :key="event.id">
          <div 
            class="absolute w-1 h-3 cursor-pointer group"
            :style="{ 
              left: `${(parseInt(event.time) / 90) * 100}%`,
              top: '-6px'
            }"
            @mouseover="showTooltip(event)"
            @mouseleave="hideTooltip"
          >
            <!-- Tooltip -->
            <div 
              v-show="tooltipEvent === event"
              class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs bg-custom-bg-2 text-white rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            >
              {{ event.text }}
            </div>
          </div>
        </template>

        <!-- Time Markers -->
        <div class="absolute -top-6 left-0 text-xs text-red-500">0'</div>
        <div class="absolute -top-6 left-1/2 -translate-x-1/2 text-xs text-custom-gray">HT</div>
        <div class="absolute -top-6 right-0 text-xs text-red-500">90'</div>
      </div>
    </div>

    <!-- Match Events -->
    <div class="space-y-4">
      <button 
        class="w-full text-center text-sm text-custom-gray hover:text-white"
        @click="showMatchTotals = !showMatchTotals"
      >
        {{ showMatchTotals ? 'Hide' : 'Show' }} Match Totals
      </button>

      <div class="text-sm text-custom-gray">
        Halftime {{ match.homeTeamScore }} - {{ match.awayTeamScore }}
      </div>

      <div class="space-y-2">
        <div v-for="event in sortedEvents" :key="event.id" class="grid grid-cols-[1fr,auto,1fr] gap-4 items-center">
          <!-- Home Team Event -->
          <div v-if="event.team === 'home'" class="flex items-center justify-end space-x-2">
            <span>{{ formatEventText(event.text) }}</span>
            <div v-if="event.type === 'yellowcard'" class="w-3 h-4 bg-yellow-400"></div>
            <div v-else-if="event.type === 'redcard'" class="w-3 h-4 bg-red-600"></div>
            <div v-else class="w-0.5 h-4" :style="{ backgroundColor: homeTeamColor }"></div>
          </div>
          <div v-else class="flex-1"></div>

          <!-- Timestamp -->
          <div class="text-custom-gray font-medium">{{ event.time }}'</div>

          <!-- Away Team Event -->
          <div v-if="event.team === 'away'" class="flex items-center space-x-2">
            <div v-if="event.type === 'yellowcard'" class="w-3 h-4 bg-yellow-400"></div>
            <div v-else-if="event.type === 'redcard'" class="w-3 h-4 bg-red-600"></div>
            <div v-else class="w-0.5 h-4" :style="{ backgroundColor: awayTeamColor }"></div>
            <span>{{ formatEventText(event.text) }}</span>
          </div>
          <div v-else class="flex-1"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Stats View -->
  <div v-else-if="activeTab === 'stats'" class="p-6 space-y-8">
    <!-- Circular Stats -->
    <div class="grid grid-cols-2 gap-x-12 gap-y-8">
      <div v-for="(stat, index) in circularStats" :key="index" class="text-center">
        <h3 class="text-sm text-gray-400 mb-3">{{ stat.label }}</h3>
        <div class="flex items-center justify-center gap-4">
          <span class="text-lg font-medium">{{ stat.home }}</span>
          <div class="relative w-16 h-16">
            <svg class="w-full h-full transform -rotate-90">
              <circle
                cx="32"
                cy="32"
                r="30"
                stroke="currentColor"
                stroke-width="3"
                fill="none"
                class="text-gray-700"
              />
              <circle
                cx="32"
                cy="32"
                r="30"
                :stroke="homeTeamColor"
                stroke-width="3"
                fill="none"
                :stroke-dasharray="`${stat.homePercentage * 1.88} ${188.4}`"
              />
              <circle
                cx="32"
                cy="32"
                r="30"
                :stroke="awayTeamColor"
                stroke-width="3"
                fill="none"
                :stroke-dasharray="`${stat.awayPercentage * 1.88} ${188.4}`"
                :stroke-dashoffset="-stat.homePercentage * 1.88"
              />
            </svg>
          </div>
          <span class="text-lg font-medium">{{ stat.away }}</span>
        </div>
      </div>
    </div>

    <!-- Bar Stats -->
    <div class="space-y-6 mt-8">
      <div v-for="(stat, index) in barStats" :key="index" class="space-y-2">
        <div class="flex justify-between text-xs text-gray-400 uppercase">
          <span>{{ stat.label }}</span>
          <div class="space-x-8">
            <span>{{ stat.home }}</span>
            <span>{{ stat.away }}</span>
          </div>
        </div>
        <div class="h-1 bg-gray-700 rounded-full overflow-hidden flex">
          <div 
            :style="{ 
              width: `${stat.homePercentage}%`,
              backgroundColor: homeTeamColor 
            }"
            class="h-full"
          ></div>
          <div 
            :style="{ 
              width: `${stat.awayPercentage}%`,
              backgroundColor: awayTeamColor 
            }"
            class="h-full"
          ></div>
        </div>
      </div>
    </div>

    <!-- Cards and Corners -->
    <div class="flex justify-between items-center pt-4">
      <div class="flex gap-6">
        <div class="flex items-center gap-2">
          <div class="w-3 h-4 bg-yellow-400"></div>
          <span class="text-sm">{{ match.stats.stats.yellow_cards?.[0] || 0 }}</span>
        </div>
        <div class="flex items-center gap-2">
          <div class="w-3 h-4 bg-red-600"></div>
          <span class="text-sm">{{ match.stats.stats.red_cards?.[0] || 0 }}</span>
        </div>
        <div class="flex items-center gap-2">
          <i class="ri-flag-2-line text-red-500"></i>
          <span class="text-sm">{{ match.stats.stats.corners?.[0] || 0 }}</span>
        </div>
      </div>
      <div class="flex gap-6">
        <div class="flex items-center gap-2">
          <span class="text-sm">{{ match.stats.stats.yellow_cards?.[1] || 0 }}</span>
          <div class="w-3 h-4 bg-yellow-400"></div>
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm">{{ match.stats.stats.red_cards?.[1] || 0 }}</span>
          <div class="w-3 h-4 bg-red-600"></div>
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm">{{ match.stats.stats.corners?.[1] || 0 }}</span>
          <i class="ri-flag-2-line text-red-500"></i>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>import { ref, computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  match: {
    type: Object,
    required: true
  }
});

const router = useRouter();
const activeTab = ref('live');
const showMatchTotals = ref(false);
const tooltipEvent = ref(null);

const generateRandomColor = () => {
  const colors = ['yellow', 'purple', 'pink'];
  return colors[Math.floor(Math.random() * colors.length)];
};

const homeTeamColor = ref(generateRandomColor());
const awayTeamColor = ref(generateRandomColor());

// Ensure the colors are different
while (homeTeamColor.value === awayTeamColor.value) {
  awayTeamColor.value = generateRandomColor();
}

const isMatchDataReady = computed(() => 
  !!props.match && 
  !!props.match.homeTeamName && 
  !!props.match.awayTeamName && 
  !!props.match.timer && 
  !!props.match.stats
);

const calculatePercentage = (value1, value2) => {
  const total = (parseInt(value1) || 0) + (parseInt(value2) || 0);
  if (total === 0) return 50;
  return ((parseInt(value1) || 0) / total) * 100;
};

const circularStats = computed(() => {
  const stats = props.match.stats.stats;
  return [
    {
      label: 'Attack',
      home: stats.attacks?.[0] || 0,
      away: stats.attacks?.[1] || 0,
      homePercentage: calculatePercentage(stats.attacks?.[0], stats.attacks?.[1]),
      awayPercentage: calculatePercentage(stats.attacks?.[1], stats.attacks?.[0])
    },
    {
      label: 'Dangerous Attack',
      home: stats.dangerous_attacks?.[0] || 0,
      away: stats.dangerous_attacks?.[1] || 0,
      homePercentage: calculatePercentage(stats.dangerous_attacks?.[0], stats.dangerous_attacks?.[1]),
      awayPercentage: calculatePercentage(stats.dangerous_attacks?.[1], stats.dangerous_attacks?.[0])
    },
    {
      label: 'Substitution',
      home: stats.substitutions?.[0] || 0,
      away: stats.substitutions?.[1] || 0,
      homePercentage: calculatePercentage(stats.substitutions?.[0], stats.substitutions?.[1]),
      awayPercentage: calculatePercentage(stats.substitutions?.[1], stats.substitutions?.[0])
    },
    {
      label: 'Possession (%)',
      home: stats.possession_rt?.[0] || 0,
      away: stats.possession_rt?.[1] || 0,
      homePercentage: parseInt(stats.possession_rt?.[0]) || 0,
      awayPercentage: parseInt(stats.possession_rt?.[1]) || 0
    }
  ];
});

const barStats = computed(() => {
  const stats = props.match.stats.stats;
  return [
    {
      label: 'Penalty',
      home: stats.penalties?.[0] || 0,
      away: stats.penalties?.[1] || 0,
      homePercentage: calculatePercentage(stats.penalties?.[0], stats.penalties?.[1]),
      awayPercentage: calculatePercentage(stats.penalties?.[1], stats.penalties?.[0])
    },
    {
      label: 'Shot on target',
      home: stats.on_target?.[0] || 0,
      away: stats.on_target?.[1] || 0,
      homePercentage: calculatePercentage(stats.on_target?.[0], stats.on_target?.[1]),
      awayPercentage: calculatePercentage(stats.on_target?.[1], stats.on_target?.[0])
    },
    {
      label: 'Shot off target',
      home: stats.off_target?.[0] || 0,
      away: stats.off_target?.[1] || 0,
      homePercentage: calculatePercentage(stats.off_target?.[0], stats.off_target?.[1]),
      awayPercentage: calculatePercentage(stats.off_target?.[1], stats.off_target?.[0])
    }
  ];
});

const formattedMatchTime = computed(() => {
  return props.match.timer?.currentTime ? `${props.match.timer.currentTime}'` : 'TBD';
});

const homePossession = computed(() => props.match.stats.stats.possession_rt?.[0] || '0');
const awayPossession = computed(() => props.match.stats.stats.possession_rt?.[1] || '0');
const homeShots = computed(() => {
  const onTarget = parseInt(props.match.stats.stats.on_target?.[0] || '0');
  const offTarget = parseInt(props.match.stats.stats.off_target?.[0] || '0');
  return onTarget + offTarget;
});
const awayShots = computed(() => {
  const onTarget = parseInt(props.match.stats.stats.on_target?.[1] || '0');
  const offTarget = parseInt(props.match.stats.stats.off_target?.[1] || '0');
  return onTarget + offTarget;
});

const determineEventType = (text) => {
  if (text.includes('Yellow Card')) return 'yellowcard';
  if (text.includes('Red Card')) return 'redcard';
  if (text.includes('Goal')) return 'goal';
  if (text.includes('Corner')) return 'corner';
  return 'other';
};

const determineEventTeam = (text) => {
  if (text.includes(props.match.homeTeamName)) return 'home';
  if (text.includes(props.match.awayTeamName)) return 'away';
  return 'home'; // Default to home team if can't determine
};

const formatEventText = (text) => {
  let formatted = text.split('-').slice(1).join('-').trim();
  formatted = formatted.replace(/^\d+(st|nd|rd|th)\s+/, '').replace(new RegExp(`(${props.match.homeTeamName}|${props.match.awayTeamName})`, 'g'), '').trim();
  return formatted;
};

const sortedEvents = computed(() => {
  if (!props.match.stats?.events) return [];
  
  return props.match.stats.events.map(event => ({
    id: event.id,
    time: event.text.split("'")[0],
    text: event.text,
    type: determineEventType(event.text),
    team: determineEventTeam(event.text)
  })).sort((a, b) => parseInt(b.time) - parseInt(a.time)); // Sort newest first
});

const matchEvents = computed(() => {
  if (!props.match.stats?.events) return [];
  
  return props.match.stats.events.map(event => ({
    id: event.id,
    time: event.text.split("'")[0],
    text: event.text
  })).sort((a, b) => parseInt(a.time) - parseInt(b.time));
});

const showTooltip = (event) => {
  tooltipEvent.value = event;
};

const hideTooltip = () => {
  tooltipEvent.value = null;
};
</script>

<style scoped>
.text-custom-gray {
  color: #bbb;
}
</style>