<template>
  <MainLayout :sports="sports">
    <div class="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div class="bg-gray-50 w-80 relative shadow-lg">
        <!-- Close button -->
        <button 
          @click="handleClose" 
          class="absolute right-0 top-0 bg-emerald-600 text-white p-2 hover:bg-emerald-700"
        >
          <i class="ri-close-line"></i>
        </button>

        <!-- Login Form -->
        <form @submit.prevent="handleSubmit" class="p-6">
          <div class="space-y-4">
            <div>
              <input
                v-model="username"
                type="text"
                placeholder="Username"
                class="w-full p-2 border border-gray-300 bg-white focus:outline-none focus:border-emerald-600"
              />
            </div>

            <div>
              <input
                v-model="password"
                type="password"
                placeholder="Password"
                class="w-full p-2 border border-gray-300 bg-white focus:outline-none focus:border-emerald-600"
              />
            </div>

            <div class="flex items-center">
              <input
                v-model="savePassword"
                type="checkbox"
                id="save-password"
                class="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
              />
              <label for="save-password" class="ml-2 text-sm text-gray-600">
                Save Password
              </label>
            </div>

            <button
              type="submit"
              class="w-full bg-emerald-600 text-white p-2 hover:bg-emerald-700 focus:outline-none"
              :disabled="isLoading"
            >
              {{ isLoading ? "Loading..." : "Login" }}
            </button>

            <div class="text-center">
              <a href="#" class="text-sm text-gray-600 hover:text-emerald-600">
                Lost Login?
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </MainLayout>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import MainLayout from '../layouts/MainLayout.vue'

const router = useRouter()
const store = useStore()

const username = ref('')
const password = ref('')
const savePassword = ref(false)
const isLoading = ref(false)
const sports = computed(() => store.state.sports || [])

const handleSubmit = async () => {
  try {
    isLoading.value = true
    await store.dispatch('auth/login', {
      username: username.value,
      password: password.value,
      rememberMe: savePassword.value
    })
    router.push('/')
  } catch (error) {
    console.error('Login failed:', error)
    // Handle login error (e.g., show error message)
  } finally {
    isLoading.value = false
  }
}

const handleClose = () => {
  router.back()
}

onMounted(async () => {
  try {
    await store.dispatch('fetchSports')
  } catch (error) {
    console.error('Failed to fetch sports data:', error)
  }
})
</script>